// category filter
(function(){
    var catfilter = $('#catfilter'),
        items = $('.list-group-item'),
        reset = $('#reset');

    function filterCat() {
        var search = this.value.toLowerCase();

        if (search.length > 1) {
            items.hide();
            items.each(function(){
                var dit = $(this);
                if (dit.text().toLowerCase().indexOf(search) != -1) {
                    dit.show();
                }
            });
        } else {
            items.show();
        }
    }
    catfilter.on('load keyup', filterCat);
    reset.on('click', function(e) {
        items.show();
        e.preventDefault();
    })
}());

// category sub items
(function(){
    var items = [];

    function subSetup() {
        var top = $('#cat-duurzame-energie-milieu'),
            subs = [
                $('#cat-afval'),
                $('#cat-bodem'),
                $('#cat-biomassa'),
                $('#cat-energiesystemen'),
                $('#cat-energiebesparing'),
                $('#cat-wind'),
                $('#cat-hydropower'),
                $('#cat-geothermie'),
                $('#cat-lucht'),
                $('#cat-water'),
                $('#cat-zon')
            ],
            active = 0;

        $(subs).each(function() {
            $(this).addClass('cat-sub');
            if (top.hasClass('active') || $(this).hasClass('active')) {
                active = 1;
            }
        });

        var catSub = $('.cat-sub');
        if (active === 1) {
            catSub.show();
            top.addClass('cat-sub-active');
        } else {
            catSub.hide();
            top.removeClass('cat-sub-active');
        }

    }
    subSetup() ;

}());

// lightslider
(function(){
    var lightslider = $('.lightslider');
    if (lightslider.length) {
        lightslider.lightSlider({
            item: 1,
            autoWidth: false,
            slideMove: 1, // slidemove will be 1 if loop is true
            slideMargin: 10,

            addClass: '',
            mode: "slide",
            useCSS: true,
            cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',//
            easing: 'linear', //'for jquery animation',////

            speed: 1000, //ms'
            auto: true,
            pauseOnHover: true,
            loop: true,
            slideEndAnimation: true,
            pause: 5000,

            keyPress: false,
            controls: false,
            // prevHtml: 'links',
            // nextHtml: 'rechts',

            rtl: false,
            adaptiveHeight: false,

            vertical: false,
            // verticalHeight: 500,
            vThumbWidth: 60,

            thumbItem: 6,
            pager: true,
            gallery: true,
            galleryMargin: 5,
            thumbMargin: 0,
            currentPagerPosition: 'middle',

            enableTouch: false,
            enableDrag: false,
            freeMove: false,
            swipeThreshold: 40,

            // responsive : [],
        });
    }
}());


// lightgallery
(function(){
    var lightgallery = $('.lightgallery')
    if (lightgallery.length) {
        lightgallery.lightGallery({
            download: false
        });
    }
}());